import {
  DashboardConnectionsDocument,
  type DashboardConnectionsQuery,
  type DashboardConnectionsQueryVariables,
} from "@app_schema";

import { client } from "@application/config/apollo";

export const DASHBOARD_CONNECTIONS_LOADER = async () => {
  const { data } = await client.query<
    DashboardConnectionsQuery,
    DashboardConnectionsQueryVariables
  >({
    query: DashboardConnectionsDocument,
    fetchPolicy: "network-only",
  });

  return data;
};
