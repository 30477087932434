import { type FC, type ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import {
  type ExecutionFragment,
  ExecutionStatusEnum,
  type RunFragment,
  RunStatusEnum,
  StepKindEnum,
  useDashboardRunsDetailsQuery,
  useRunChatMutation,
} from "@app_schema";

import { useActionCableSubscription } from "@application/hooks/use_action_cable_subscription";

import { groupParentID } from "@application/utilities/group_parent_id";

import { Attribute } from "@styled/attribute";
import { Chat } from "@styled/chat";
import { ChatBubble } from "@styled/chat_bubble";
import { FileList } from "@styled/file_list";
import { Headline } from "@styled/headline";
import { HTML } from "@styled/html";
import { Node } from "@styled/node";
import { NodeButtonToggle } from "@styled/node_button_toggle";
import { NodeContent } from "@styled/node_content";
import { NodeGroup } from "@styled/node_group";
import { NodeList } from "@styled/node_list";
import { NodeMenu } from "@styled/node_menu";
import { NodeName } from "@styled/node_name";
import { NodeSummary } from "@styled/node_summary";
import { Page } from "@styled/page";

import { ExecutionCopyIconButton } from "./execution_copy_icon_button";
import { ExecutionDetailButton } from "./execution_detail_button";
import { ExecutionRetryIconButton } from "./execution_retry_icon_button";
import { ExecutionRetryNodeButton } from "./execution_retry_node_button";
import { ExecutionStatus } from "./execution_status";
import { ExecutionTimer } from "./execution_timer";
import { RunDestroyButton } from "./run_destroy_button";
import { RunName } from "./run_name";
import { RunPauseButton } from "./run_pause_button";
import { RunResumeButton } from "./run_resume_button";
import { RunStatus } from "./run_status";

const DashboardRunChat: FC<{
  run: RunFragment;
  children: ReactNode;
}> = ({ run, children }) => {
  const [execute, { loading }] = useRunChatMutation();

  return (
    <Chat
      loading={loading}
      disabled={run.status !== RunStatusEnum.Paused}
      chat={(input) => execute({ variables: { runID: run.id, input } })}
    >
      {children}
    </Chat>
  );
};

const ExecutionNode: FC<{
  run: RunFragment;
  execution: ExecutionFragment;
  onToggle(expanded: boolean): void;
  expanded?: boolean;
}> = ({ run, execution, expanded, onToggle }) => (
  <Node>
    <NodeContent>
      <NodeName>
        <ExecutionStatus execution={execution} />
        <div>{execution.step.name}</div>
        <ExecutionTimer execution={execution} />
      </NodeName>
      {expanded && (
        <NodeSummary>
          <div className="flex flex-col gap-2">
            {(execution.prompt || execution.output) && (
              <div className="flex gap-2">
                {execution.prompt && (
                  <ExecutionDetailButton
                    display="prompt"
                    execution={execution}
                  />
                )}
                {execution.output && (
                  <ExecutionDetailButton
                    display="output"
                    execution={execution}
                  />
                )}
              </div>
            )}

            {execution.html && (
              <Attribute name="Result">
                <HTML html={execution.html} />
              </Attribute>
            )}

            {execution.error && (
              <Attribute name="Error">{execution.error}</Attribute>
            )}

            {execution.files.length > 0 && (
              <Attribute name="Files">
                <FileList attachments={execution.files} />
              </Attribute>
            )}
          </div>
        </NodeSummary>
      )}
    </NodeContent>
    <NodeMenu>
      <ExecutionRetryNodeButton execution={execution} run={run} />
      <NodeButtonToggle onToggle={onToggle} expanded={expanded} />
    </NodeMenu>
  </Node>
);

const ExecutionChatBubble: FC<{
  run: RunFragment;
  execution: ExecutionFragment;
  role: "user" | "system";
}> = ({ execution, run, role }) => {
  const { html, files } = execution;
  if (html === null || html === undefined) return null;

  return (
    <ChatBubble
      role={role}
      html={html}
      files={files}
      actions={
        <>
          <ExecutionRetryIconButton execution={execution} run={run} />
          <ExecutionCopyIconButton execution={execution} run={run} />
        </>
      }
    />
  );
};

const ExecutionIteration: FC<{
  iteration: number;
  run: RunFragment;
  groups: Map<string, ExecutionFragment[]>;
  executions: ExecutionFragment[];
}> = ({ iteration, run, groups, executions }) => {
  const [expanded, setExpanded] = useState<boolean | undefined>(true);

  const root = (
    <Node>
      <NodeContent>
        <NodeName>iteration = {iteration}</NodeName>
      </NodeContent>
      <NodeMenu>
        <NodeButtonToggle onToggle={setExpanded} expanded={expanded} />
      </NodeMenu>
    </Node>
  );

  return (
    <NodeGroup key={iteration} root={root}>
      {expanded && (
        <ExecutionList run={run} executions={executions} groups={groups} />
      )}
    </NodeGroup>
  );
};

const ExecutionItem: FC<{
  run: RunFragment;
  groups: Map<string, ExecutionFragment[]>;
  execution: ExecutionFragment;
}> = ({ run, groups, execution }) => {
  const [expanded, setExpanded] = useState<boolean | undefined>(false);
  const children = groups.get(execution.id);

  const node = (() => {
    switch (execution.step.kind) {
      case StepKindEnum.Input:
        return (
          <ExecutionChatBubble run={run} execution={execution} role="user" />
        );
      case StepKindEnum.Output:
        return (
          <ExecutionChatBubble run={run} execution={execution} role="system" />
        );
      default:
        return (
          <ExecutionNode
            run={run}
            execution={execution}
            expanded={expanded}
            onToggle={setExpanded}
          />
        );
    }
  })();

  if (!children) return node;
  else {
    const grouped = children.reduce((memo, child) => {
      if (memo.has(child.iteration)) {
        memo.get(child.iteration).push(child);
      } else {
        memo.set(child.iteration, [child]);
      }
      return memo;
    }, new Map());
    const iterations = Array.from(grouped.keys()).sort((a, b) => a - b);

    return (
      <NodeGroup root={node}>
        {expanded &&
          iterations.map((iteration) => (
            <ExecutionIteration
              key={iteration}
              iteration={iteration}
              executions={grouped.get(iteration)}
              run={run}
              groups={groups}
            />
          ))}
      </NodeGroup>
    );
  }
};

const ExecutionList: FC<{
  run: RunFragment;
  executions: ExecutionFragment[];
  groups: Map<string, ExecutionFragment[]>;
}> = ({ run, executions, groups }) => (
  <NodeList>
    {executions.map((execution) => (
      <ExecutionItem
        key={execution.id}
        run={run}
        execution={execution}
        groups={groups}
      />
    ))}
  </NodeList>
);

export const DashboardRunsDetails: FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [channel] = useState(() => ({ channel: "RunChannel", id: id! }));
  const { data, loading, error, refetch } = useDashboardRunsDetailsQuery({
    variables: { id: id! },
  });

  const subscription = useActionCableSubscription(channel, () => {
    refetch({ id: id! });
  });

  useEffect(() => {
    if (subscription === "connected") refetch();
  }, [subscription, refetch]);

  const retry = () => refetch({ id: id! });

  const run = data?.run;
  if (!run) return null;

  const executions = run.executions.filter(
    ({ status }) => status !== ExecutionStatusEnum.Paused,
  );

  const groups = groupParentID(executions);

  return (
    <DashboardRunChat run={run}>
      <Page loading={loading} error={error} retry={retry}>
        <Headline title={<RunName run={run} />}>
          <RunStatus run={run} />
          <RunPauseButton run={run} />
          <RunResumeButton run={run} />
          <RunDestroyButton
            run={run}
            onDestroy={() => navigate("/dashboard/runs")}
          />
        </Headline>

        <ExecutionList
          run={run}
          groups={groups}
          executions={executions.filter(({ parentID }) => !parentID)}
        />
      </Page>
    </DashboardRunChat>
  );
};
