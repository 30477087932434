import { type FC } from "react";

import { clsx } from "clsx";

import { faPlusSquare } from "@fortawesome/pro-duotone-svg-icons/faPlusSquare";
import { faTrash } from "@fortawesome/pro-duotone-svg-icons/faTrash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type JSONSchema } from "@application/types/json_schema";
import {
  type JSONSchemaArrayNode,
  type JSONSchemaNode,
  type JSONSchemaObjectNode,
} from "@application/types/json_schema_node";
import { type JSONSchemaProperty } from "@application/types/json_schema_property";
import { JSONSchemaType } from "@application/types/json_schema_type";

import { Button } from "./button";
import { Checkbox } from "./checkbox";
import { Fields } from "./fields";
import { Input } from "./input";
import { Label } from "./label";
import { Select } from "./select";

const DEFAULT_NODE: JSONSchemaNode = {
  type: JSONSchemaType.String,
  title: "",
  description: "",
  required: true,
};

const JSONSchemaNodeBaseFields: FC<{
  node: JSONSchemaNode;
  onChange(node: JSONSchemaNode): void;
  onDelete?(): void;
}> = ({ node, onChange, onDelete }) => (
  <Fields>
    <Input
      type="text"
      placeholder="Name"
      value={node?.title}
      onChange={(event) => onChange({ ...node, title: event.target.value })}
    />

    <Input
      type="text"
      placeholder="Description"
      value={node.description}
      onChange={(event) =>
        onChange({ ...node, description: event.target.value })
      }
    />

    <Select
      full
      value={node.type}
      onChange={(event) => {
        const type: JSONSchemaType = event.target.value as JSONSchemaType;
        switch (type) {
          case JSONSchemaType.Array:
            return onChange({
              ...node,
              type,
              items: DEFAULT_NODE,
            });
          case JSONSchemaType.Object:
            return onChange({
              ...node,
              type,
              properties: [DEFAULT_NODE],
            });
          default:
            return onChange({
              ...node,
              type,
            });
        }
      }}
    >
      <option disabled={!!node.type}>- Type -</option>
      <option value={JSONSchemaType.Object}>Object</option>
      <option value={JSONSchemaType.Array}>Array</option>
      <option value={JSONSchemaType.String}>String</option>
      <option value={JSONSchemaType.Integer}>Integer</option>
      <option value={JSONSchemaType.Number}>Number</option>
      <option value={JSONSchemaType.Boolean}>Boolean</option>
    </Select>

    {onDelete && (
      <Label className="flex items-center gap-2">
        <Checkbox
          checked={node.required}
          onChange={(event) => {
            onChange({ ...node, required: event.target.checked });
          }}
        />
        <span>required</span>
      </Label>
    )}

    {onDelete && (
      <Button type="button" color="rose" onClick={onDelete}>
        <FontAwesomeIcon icon={faTrash} />
      </Button>
    )}
  </Fields>
);

const JSONSchemaNodeArrayFields: FC<{
  root?: boolean;
  node: JSONSchemaArrayNode;
  onChange(node: JSONSchemaArrayNode): void;
}> = ({ node, onChange }) => (
  <JSONSchemaNodeField
    node={node.items}
    onChange={(value) => {
      onChange({ ...node, items: value });
    }}
  />
);

const JSONSchemaNodeObjectFields: FC<{
  root?: boolean;
  node: JSONSchemaObjectNode;
  onChange(node: JSONSchemaObjectNode): void;
}> = ({ node, onChange }) => (
  <div className="space-y-4">
    {node.properties.map((property, key) => (
      <JSONSchemaNodeField
        node={property}
        key={key}
        onChange={(value) => {
          onChange({
            ...node,
            properties: node.properties.map((existing, index) =>
              index === key ? value : existing,
            ),
          });
        }}
        onDelete={() => {
          onChange({
            ...node,
            properties: node.properties.filter((_, index) => index !== key),
          });
        }}
      />
    ))}
    <Button
      type="button"
      onClick={() =>
        onChange({
          ...node,
          properties: [...node.properties, DEFAULT_NODE],
        })
      }
    >
      <FontAwesomeIcon icon={faPlusSquare} />
      Add Property
    </Button>
  </div>
);

const JSONSchemaNodeField: FC<{
  node: JSONSchemaNode;
  root?: boolean;
  onChange(node: JSONSchemaNode): void;
  onDelete?(): void;
}> = ({ node, root, onChange, onDelete }) => (
  <div className="space-y-4">
    {!root && (
      <JSONSchemaNodeBaseFields
        node={node}
        onChange={onChange}
        onDelete={onDelete}
      />
    )}

    {node.type === JSONSchemaType.Array && (
      <div className={clsx(!root && "ml-4")}>
        <JSONSchemaNodeArrayFields
          root={root}
          node={node}
          onChange={onChange}
        />
      </div>
    )}

    {node.type === JSONSchemaType.Object && (
      <div className={clsx(!root && "ml-4")}>
        <JSONSchemaNodeObjectFields
          root={root}
          node={node}
          onChange={onChange}
        />
      </div>
    )}
  </div>
);

const convertJSONSchemaPropertyToJSONSchemaNode = (
  property: JSONSchemaProperty,
  required?: boolean,
): JSONSchemaNode => {
  const base = {
    title: property.title,
    description: property.description,
    required,
  };

  if (property.type === JSONSchemaType.Array) {
    return {
      ...base,
      type: JSONSchemaType.Array,
      items: convertJSONSchemaPropertyToJSONSchemaNode(property.items),
    };
  }

  if (property.type === JSONSchemaType.Object) {
    return {
      ...base,
      type: JSONSchemaType.Object,
      properties: Object.values(property.properties).map((child) =>
        convertJSONSchemaPropertyToJSONSchemaNode(
          child,
          property.required.includes(child.title),
        ),
      ),
    };
  }

  return {
    type: property.type,
    ...base,
  };
};

const convertJSONSchemaNodeToJSONSchemaProperty = (
  node: JSONSchemaNode,
): JSONSchemaProperty => {
  const base = {
    title: node.title,
    description: node.description,
  };

  if (node.type === JSONSchemaType.Array) {
    return {
      ...base,
      type: JSONSchemaType.Array,
      items: convertJSONSchemaNodeToJSONSchemaProperty(node.items!),
    };
  }

  if (node.type === JSONSchemaType.Object) {
    const properties: Record<string, JSONSchemaProperty> = {};

    for (const property of node.properties) {
      properties[property.title] =
        convertJSONSchemaNodeToJSONSchemaProperty(property);
    }

    return {
      ...base,
      type: JSONSchemaType.Object,
      properties,
      required: node.properties
        .filter(({ required }) => required)
        .map(({ title }) => title),
    };
  }

  return {
    type: node.type,
    ...base,
  };
};

export const JSONSchemaField: FC<{
  schema: JSONSchema;
  onChange(_: JSONSchema): void;
}> = ({ schema, onChange }) => (
  <JSONSchemaNodeField
    root
    node={convertJSONSchemaPropertyToJSONSchemaNode(schema)}
    onChange={(node) => {
      onChange({
        ...convertJSONSchemaNodeToJSONSchemaProperty(node),
      });
    }}
  />
);
