import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Action__CodeImage, type StepInput } from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { SelectField } from "@styled/select_field";
import { TextAreaField } from "@styled/text_area_field";

const IMAGES = [
  { name: "Python", value: Action__CodeImage.Python },
  { name: "Ruby", value: Action__CodeImage.Ruby },
  { name: "Scraper", value: Action__CodeImage.Scrape },
];

export const DashboardStepsFormActionCodeFieldset: FC = () => {
  const { register, unregister, watch } = useFormContext<StepInput>();

  useEffect(() => {
    register("action.code");
    return () => unregister("action.code");
  }, [register, unregister]);

  return (
    <Fieldset>
      <SelectField
        {...register("action.code.image", { required: "required" })}
        id="step_action_execution_image"
        label="Image:"
      >
        <option disabled={!!watch("action.code.image")} value="">
          - Image -
        </option>
        {IMAGES.map(({ name, value }, key) => (
          <option value={value} key={key}>
            {name}
          </option>
        ))}
      </SelectField>

      <TextAreaField
        mono
        {...register("action.code.template", {
          required: "required",
        })}
        id="step_action_execution_template"
        label="Template:"
        placeholder="Template"
        rows={8}
      />
    </Fieldset>
  );
};
