import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { faFile } from "@fortawesome/pro-duotone-svg-icons/faFile";
import { faTemperatureList } from "@fortawesome/pro-duotone-svg-icons/faTemperatureList";

import {
  Action__TranscriptionFormat,
  Action__TranscriptionModel,
  type StepInput,
} from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { InputField } from "@styled/input_field";
import { SelectField } from "@styled/select_field";
import { TextAreaField } from "@styled/text_area_field";

const DEFAULT_MODEL = Action__TranscriptionModel.OpenaiWhisper;
const DEFAULT_FORMAT = Action__TranscriptionFormat.Json;
const DEFAULT_TEMPERATURE = 0.0;

export const DashboardStepsFormActionTranscriptionFieldset: FC = () => {
  const { register, unregister, watch } = useFormContext<StepInput>();
  const model = watch("action.transcription.model");
  const format = watch("action.transcription.format");

  useEffect(() => {
    register("action.transcription");
    return () => unregister("action.transcription");
  }, [register, unregister]);

  return (
    <Fieldset>
      <InputField
        {...register("action.transcription.path", { required: "required" })}
        id="step_action_transcription.temperature"
        icon={faFile}
        label="Path:"
        placeholder="Path"
        tooltip={{
          tip: <>The path to a file variable separated by periods.</>,
        }}
      />

      <SelectField
        defaultValue={DEFAULT_MODEL}
        {...register("action.transcription.model", { required: "required" })}
        id="step_action_transcription.model"
        label="Model:"
      >
        <option disabled={!!model} value="">
          - Model -
        </option>

        <option value={Action__TranscriptionModel.OpenaiWhisper}>
          Open AI - Whisper
        </option>
      </SelectField>

      <InputField
        defaultValue={DEFAULT_TEMPERATURE}
        {...register("action.transcription.temperature", {
          required: "required",
          min: 0,
          max: 1,
          valueAsNumber: true,
        })}
        id="step_action_transcription.temperature"
        step={0.1}
        icon={faTemperatureList}
        type="number"
        label="Temperature:"
        placeholder="Temperature"
      />

      <TextAreaField
        {...register("action.transcription.prompt")}
        id="step_action_transcription.prompt"
        label="Prompt (optional):"
        placeholder="Prompt"
        rows={8}
      />

      <SelectField
        defaultValue={DEFAULT_FORMAT}
        {...register("action.transcription.format")}
        id="step_action_transcription.formatter_kind"
        label="Format:"
      >
        <option disabled={!!format} value="">
          - Kind -
        </option>
        <option value={Action__TranscriptionFormat.Json}>JSON</option>
        <option value={Action__TranscriptionFormat.Text}>TEXT</option>
        <option value={Action__TranscriptionFormat.Srt}>SRT</option>
        <option value={Action__TranscriptionFormat.Vtt}>VTT</option>
      </SelectField>
    </Fieldset>
  );
};
