import { type FC, useState } from "react";

import { faChevronDown } from "@fortawesome/pro-duotone-svg-icons/faChevronDown";
import { faChevronRight } from "@fortawesome/pro-duotone-svg-icons/faChevronRight";
import { faCodeBranch } from "@fortawesome/pro-duotone-svg-icons/faCodeBranch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  type Run,
  type Scheduling,
  type User,
  type Webhook,
  type Workflow,
  useDashboardRunChildrenQuery,
  useDashboardRunsListQuery,
} from "@app_schema";

import { useDebounce } from "@application/hooks/use_debounce";

import { Fields } from "@styled/fields";
import { IconButton } from "@styled/icon_button";
import { List } from "@styled/list";
import { ListChildren } from "@styled/list_children";
import { ListItem } from "@styled/list_item";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemIcon } from "@styled/list_item_icon";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { MineSwitch } from "@styled/mine_switch";
import { Page } from "@styled/page";
import { Pagination } from "@styled/pagination";
import { SearchInputField } from "@styled/search_input_field";
import { ViewLink } from "@styled/view_link";

import { RunDestroyButton } from "./run_destroy_button";
import { RunName } from "./run_name";
import { RunStatus } from "./run_status";
import { RunTimer } from "./run_timer";
import { RunTriggerSummary } from "./run_trigger_summary";

type RunListFragment = Pick<
  Run,
  "id" | "parentID" | "started" | "finished" | "status" | "nested"
> & {
  user?: Pick<User, "id" | "name" | "me">;
  workflow: Pick<Workflow, "id" | "name">;
  webhook?: Pick<Webhook, "id" | "description"> | null;
  scheduling?: Pick<Scheduling, "id" | "occurrence"> | null;
};

const DashboardRunListItem: FC<{
  run: RunListFragment;
}> = ({ run }) => {
  const [expanded, setExpanded] = useState(false);
  const { data, loading } = useDashboardRunChildrenQuery({
    variables: { id: run.id },
    skip: !expanded,
  });
  const children = data?.run?.children ?? [];

  return (
    <>
      <ListItem>
        {run.parentID && (
          <ListItemIcon>
            <FontAwesomeIcon icon={faCodeBranch} />
          </ListItemIcon>
        )}
        {run.nested && (
          <ListItemIcon>
            <IconButton
              loading={loading}
              onClick={() => setExpanded(!expanded)}
              icon={expanded ? faChevronDown : faChevronRight}
            />
          </ListItemIcon>
        )}
        <ListItemText>
          <ListItemTitle>
            <RunName run={run} />
          </ListItemTitle>
          <ListItemSummary>
            <RunTimer run={run} /> <RunTriggerSummary run={run} />
          </ListItemSummary>
        </ListItemText>

        <ListItemControls>
          <RunStatus run={run} />
          <ViewLink to={`/dashboard/runs/${run.id}`} />
          <RunDestroyButton run={run} />
        </ListItemControls>
      </ListItem>

      {expanded && (
        <ListChildren>
          <DashboardRunList runs={children} />
        </ListChildren>
      )}
    </>
  );
};

const DashboardRunList: FC<{
  runs: RunListFragment[];
}> = ({ runs }) => (
  <>{runs?.map((run) => <DashboardRunListItem key={run.id} run={run} />)}</>
);

export const DashboardRunsList: FC = () => {
  const [mine, setMine] = useState<boolean | undefined>(undefined);
  const [query, setQuery] = useState<string | undefined>();
  const { data, error, loading, refetch, fetchMore } =
    useDashboardRunsListQuery({
      variables: { mine, query: useDebounce(query) },
    });

  if (!data) return null;

  const pagination = data.runs.pagination;
  const runs = data.runs.entries.filter((run) => !run.deleted);

  const retry = () => refetch();

  return (
    <Page error={error} loading={loading} retry={retry}>
      <Fields>
        <SearchInputField query={query} onSearch={setQuery} />
        <MineSwitch mine={mine} onChange={setMine} />
      </Fields>

      <List>
        <DashboardRunList runs={runs} />
      </List>

      <Pagination
        {...pagination}
        onPage={(page) => {
          fetchMore({
            variables: { page },
            updateQuery: (_, { fetchMoreResult }) => fetchMoreResult,
          });
        }}
      />
    </Page>
  );
};
