import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import { faIdCardClip as faUsername } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";
import { faLock as faPassword } from "@fortawesome/pro-duotone-svg-icons/faLock";
import { faNote as faDescription } from "@fortawesome/pro-duotone-svg-icons/faNote";

import { WebhookInput, useWebhookBuildMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Sentence } from "@styled/sentence";

import { WorkflowSelectField } from "./workflow_select_field";

const CHARACTERS =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789".split("");

const random = (length: number): string =>
  Array.from(
    { length },
    () => CHARACTERS[Math.floor(Math.random() * CHARACTERS.length)],
  ).join("");

export const DashboardWebhooksForm: FC = () => {
  const navigate = useNavigate();
  const form = useForm<WebhookInput>({
    defaultValues: {
      username: random(32),
      password: random(32),
    },
  });

  const [execute, { loading, data }] = useWebhookBuildMutation();
  const errors = data?.webhooks.build?.errors;

  const onSubmit = async (input: WebhookInput) => {
    if (loading) return;
    const result = await execute({ variables: { input } });
    const webhook = result.data?.webhooks.build?.webhook;
    if (webhook)
      navigate(`/dashboard/triggers/webhooks/${webhook.id}`, {
        state: { url: webhook.url },
      });
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <WorkflowSelectField
            name="workflowID"
            id="workflow_id"
            label="Workflow:"
          />

          <InputField
            {...form.register("description", { required: "required" })}
            id="description"
            label="Description:"
            placeholder="Description"
            icon={faDescription}
            errors={errors}
          />

          <InputField
            {...form.register("username", { required: "required" })}
            id="user"
            disabled
            type="text"
            label="Username:"
            placeholder="Username"
            icon={faUsername}
            errors={errors}
          />

          <InputField
            {...form.register("password", { required: "required" })}
            id="password"
            disabled
            type="text"
            label="Password:"
            placeholder="Password"
            icon={faPassword}
            errors={errors}
          />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
