import { type SelectHTMLAttributes, forwardRef } from "react";

import { TemplateLanguage } from "@app_schema";

import { SelectField } from "@styled/select_field";

type T = HTMLSelectElement;
type P = SelectHTMLAttributes<T> & {
  placeholder?: boolean;
  label?: string;
  name: string;
};

export const TemplateLanguageSelectField = forwardRef<T, P>(
  ({ label = "Language:", placeholder, ...props }, ref) => (
    <SelectField ref={ref} label={label} {...props}>
      <option value="" disabled={!placeholder}>
        - Language -
      </option>
      <option value={TemplateLanguage.Liquid}>Liquid</option>
      <option value={TemplateLanguage.Mustache}>Mustache</option>
    </SelectField>
  ),
);
