import { type FC, type ReactNode } from "react";

export const Well: FC<{
  name?: string;
  actions?: ReactNode;
  children: ReactNode;
}> = ({ name, actions, children }) => (
  <div className="flex flex-col gap-2">
    {name && <div className="font-medium text-slate-400">{name}:</div>}
    <div className="relative overflow-x-auto rounded-md border border-slate-200 bg-slate-100 p-4">
      {children}
      {actions && <div className="absolute right-2 top-2">{actions}</div>}
    </div>
  </div>
);
