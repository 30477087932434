import { type FC } from "react";

import { type Run, type Scheduling, type Webhook } from "@app_schema";

import { DateTimeFormatter } from "@styled/date_time_formatter";

const WebhookSummary: FC<{
  webhook: Pick<Webhook, "description">;
}> = ({ webhook }) => {
  return <>triggered by webhook "{webhook.description}"</>;
};

const SchedulingSummary: FC<{
  scheduling: Pick<Scheduling, "occurrence">;
}> = ({ scheduling }) => {
  return (
    <>
      triggered by schedule "<DateTimeFormatter iso={scheduling.occurrence} />"
    </>
  );
};

export const RunTriggerSummary: FC<{
  run: Pick<Run, "id"> & {
    webhook?: Pick<Webhook, "description"> | null;
    scheduling?: Pick<Scheduling, "occurrence"> | null;
  };
}> = ({ run }) => {
  if (run.webhook) return <WebhookSummary webhook={run.webhook} />;
  if (run.scheduling) return <SchedulingSummary scheduling={run.scheduling} />;

  return null;
};
