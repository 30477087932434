import { type FC } from "react";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFile } from "@fortawesome/pro-duotone-svg-icons/faFile";
import { faFileAudio } from "@fortawesome/pro-duotone-svg-icons/faFileAudio";
import { faFileImage } from "@fortawesome/pro-duotone-svg-icons/faFileImage";
import { faFileText } from "@fortawesome/pro-duotone-svg-icons/faFileText";
import { faFileVideo } from "@fortawesome/pro-duotone-svg-icons/faFileVideo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ActiveStorageAttachmentKindEnum,
  type AttachmentFragment,
} from "@app_schema";

const ICONS: Record<ActiveStorageAttachmentKindEnum, IconProp> = {
  [ActiveStorageAttachmentKindEnum.Audio]: faFileAudio,
  [ActiveStorageAttachmentKindEnum.Image]: faFileImage,
  [ActiveStorageAttachmentKindEnum.Video]: faFileVideo,
  [ActiveStorageAttachmentKindEnum.Text]: faFileText,
  [ActiveStorageAttachmentKindEnum.Other]: faFile,
};

export const FileIcon: FC<{
  attachment: AttachmentFragment;
}> = ({ attachment }) => <FontAwesomeIcon icon={ICONS[attachment.kind]} />;
