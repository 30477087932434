import { type FC } from "react";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faGithub } from "@fortawesome/free-brands-svg-icons/faGithub";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  type ConnectionFragment,
  Connection__Provider,
  useDashboardConnectionsQuery,
} from "@app_schema";

import { CSRF_PARAM, CSRF_TOKEN } from "@application/config/csrf";

import { Button } from "@styled/button";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { Page } from "@styled/page";

const NAME: Record<Connection__Provider, string> = {
  [Connection__Provider.Github]: "GitHub",
};

const ICON: Record<Connection__Provider, IconProp> = {
  [Connection__Provider.Github]: faGithub,
};

const ConnectionButton: FC<{
  connection: ConnectionFragment;
}> = ({ connection: { uri, connected } }) => (
  <form method="post" action={uri}>
    <input type="hidden" name={CSRF_PARAM!} value={CSRF_TOKEN!} />
    <Button full type="submit" color={connected ? "white" : "indigo"}>
      {connected ? "Reconnect" : "Connect"}
    </Button>
  </form>
);

const ConnectionTitle: FC<{
  connection: ConnectionFragment;
}> = ({ connection: { provider } }) => (
  <>
    <FontAwesomeIcon icon={ICON[provider]} /> {NAME[provider]}
  </>
);

export const ConnectionListItem: FC<{
  connection: ConnectionFragment;
}> = ({ connection }) => (
  <ListItem>
    <ListItemText>
      <ListItemTitle>
        <ConnectionTitle connection={connection} />
      </ListItemTitle>
    </ListItemText>
    <ListItemControls>
      <ConnectionButton connection={connection} />
    </ListItemControls>
  </ListItem>
);

export const DashboardConnections: FC = () => {
  const { data } = useDashboardConnectionsQuery();
  if (!data) return null;

  return (
    <Page>
      <List>
        <ConnectionListItem connection={data.github} />
      </List>
    </Page>
  );
};
