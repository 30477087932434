import { type FC } from "react";

import { faCheck } from "@fortawesome/pro-duotone-svg-icons/faCheck";
import { faCopy } from "@fortawesome/pro-duotone-svg-icons/faCopy";

import { type ExecutionFragment, type RunFragment } from "@app_schema";

import { useClipboard } from "@application/hooks/use_clipboard";

import { IconButton } from "@styled/icon_button";

export const ExecutionCopyIconButton: FC<{
  run: RunFragment;
  execution: ExecutionFragment;
}> = ({ execution }) => {
  const { copy, copied } = useClipboard(execution.result ?? "");

  return (
    <IconButton
      disabled={!execution.result}
      onClick={copy}
      icon={copied ? faCheck : faCopy}
    />
  );
};
