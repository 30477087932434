import { type FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router";

import { faNote as faDescription } from "@fortawesome/pro-duotone-svg-icons/faNote";

import { ScheduleInput, useScheduleBuildMutation } from "@app_schema";

import { Button } from "@styled/button";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Link } from "@styled/link";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Sentence } from "@styled/sentence";

import { WorkflowSelectField } from "./workflow_select_field";

export const DashboardSchedulesForm: FC = () => {
  const navigate = useNavigate();
  const form = useForm<ScheduleInput>();

  const [execute, { loading, data }] = useScheduleBuildMutation();
  const errors = data?.schedules.build?.errors;

  const onSubmit = async (input: ScheduleInput) => {
    if (loading) return;
    const result = await execute({ variables: { input } });
    const schedule = result.data?.schedules.build?.schedule;
    if (schedule) navigate(`/dashboard/triggers/schedules`);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <WorkflowSelectField
            id="workflow_id"
            name="workflowID"
            label="Workflow:"
          />

          <InputField
            {...form.register("description", { required: "required" })}
            id="description"
            label="Description:"
            placeholder="Description"
            icon={faDescription}
            errors={errors}
          />

          <InputField
            {...form.register("frequency", { required: "required" })}
            id="description"
            label="Frequency:"
            placeholder="* * * * *"
            hint={
              <>
                For help with the CRON syntax try:{" "}
                <Link target="_blank" href="https://crontab.guru/">
                  crontab.guru
                </Link>
              </>
            }
            icon={faDescription}
            errors={errors}
          />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
