import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { faFilter } from "@fortawesome/pro-duotone-svg-icons/faFilter";

import { type StepInput } from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { InputField } from "@styled/input_field";

import { WorkflowSelectField } from "./workflow_select_field";

const DashboardStepsFormActionBranchTip: FC = () => (
  <>
    <p>Params are passed into a branched workflow by path or template:</p>
    <p> - To use a path simply specify "." separated keys (e.g. x.y.z).</p>
    <p> - To use a template specify wrap in &#123;&#123;&#125;&#125;.</p>
    <p>Params are accessible via the "params" key in branched workflows.</p>
    <p>By default all run variables are accessible in a branched run params.</p>
  </>
);

export const DashboardStepsFormActionBranchFieldset: FC = () => {
  const { register, unregister } = useFormContext<StepInput>();

  useEffect(() => {
    register("action.branch");
    return () => unregister("action.branch");
  }, [register, unregister]);

  return (
    <Fieldset>
      <WorkflowSelectField
        id="action_branch_workflow_id"
        name="action.branch.workflowID"
        label="Workflow:"
      />

      <InputField
        {...register("action.branch.params")}
        icon={faFilter}
        tooltip={{
          tip: <DashboardStepsFormActionBranchTip />,
        }}
        id="action_branch_params"
        name="action.branch.params"
        label="Params:"
        type="text"
        placeholder="e.g. x.y.z"
      />
    </Fieldset>
  );
};
