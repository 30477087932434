import { type FC, type MouseEvent, useState } from "react";

import {
  ActiveStorageAttachmentKindEnum,
  type AttachmentFragment,
} from "@app_schema";

import { FileDialog } from "./file_dialog";
import { FileIcon } from "./file_icon";

export const FileLink: FC<{
  attachment: AttachmentFragment;
}> = ({ attachment }) => {
  const [viewing, setViewing] = useState<boolean>(false);

  const onClick = (event: MouseEvent) => {
    if (attachment.kind === ActiveStorageAttachmentKindEnum.Other) return;

    event.preventDefault();
    event.stopPropagation();

    setViewing(!viewing);
  };

  return (
    <>
      <a
        download
        href={attachment.url}
        className="flex items-center gap-2 rounded-md bg-slate-200 p-2 text-slate-600 hover:bg-slate-200/50 hover:text-slate-400"
        onClick={onClick}
      >
        <FileIcon attachment={attachment} /> {attachment.filename}
      </a>

      {viewing && (
        <FileDialog
          attachment={attachment}
          onClose={() => setViewing(!viewing)}
        />
      )}
    </>
  );
};
