import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { type StepInput } from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { InputField } from "@styled/input_field";

export const DashboardStepsFormActionLearnFieldset: FC = () => {
  const { register, unregister } = useFormContext<StepInput>();

  useEffect(() => {
    register("action.learn");
    return () => unregister("action.learn");
  }, [register, unregister]);

  return (
    <Fieldset>
      <InputField
        {...register("action.learn.name", { required: "required" })}
        id="step_action_learn_name"
        label="Name:"
        placeholder="Name"
      />
      <InputField
        {...register("action.learn.code", { required: "required" })}
        id="step_action_learn_code"
        label="Code:"
        placeholder="Code"
      />
      <InputField
        {...register("action.learn.test", { required: "required" })}
        id="step_action_learn_test"
        label="Test:"
        placeholder="Test"
      />
      <InputField
        {...register("action.learn.documentation", {
          required: "required",
        })}
        id="step_action_learn_documentation"
        label="Documentation:"
        placeholder="Documentation"
      />
    </Fieldset>
  );
};
