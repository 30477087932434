import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Action__InputKind, type StepInput } from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { SelectField } from "@styled/select_field";

export const DashboardStepsFormActionInputFieldset: FC = () => {
  const { register, unregister, watch } = useFormContext<StepInput>();
  const kind = watch("action.input.kind");

  useEffect(() => {
    register("action.input");
    return () => unregister("action.input");
  }, [register, unregister]);

  return (
    <Fieldset>
      <SelectField
        {...register("action.input.kind", { required: "required" })}
        id="step_action_input_kind"
        label="Medium:"
      >
        <option disabled={!!kind} value="">
          - Medium -
        </option>
        <option value={Action__InputKind.Chat}>Chat</option>
      </SelectField>
    </Fieldset>
  );
};
