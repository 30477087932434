import { type FC } from "react";
import { useActiveStorage } from "react-activestorage";

import { Progress } from "./progress";

type Blob = {
  signed_id: string;
  filename: string;
};

export const FileUploader: FC<{
  onUpload(blob: Blob): void;
  file: File;
}> = ({ file, onUpload }) => {
  const { progress } = useActiveStorage(file, async ({ blob }) => {
    if (!blob) return;
    onUpload(blob);
  });

  return (
    <>{progress && <Progress value={progress.loaded / progress.total} />}</>
  );
};
