import { type FC } from "react";

import { faRepeat } from "@fortawesome/pro-duotone-svg-icons/faRepeat";
import { faSpinner } from "@fortawesome/pro-duotone-svg-icons/faSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  type ExecutionFragment,
  type RunFragment,
  RunStatusEnum,
  useExecutionRetryMutation,
} from "@app_schema";

import { NodeButton } from "@styled/node_button";

export const ExecutionRetryNodeButton: FC<{
  run: RunFragment;
  execution: ExecutionFragment;
}> = ({ run, execution }) => {
  const disabled = run.status === RunStatusEnum.Running;
  const [execute, { loading }] = useExecutionRetryMutation({
    variables: { id: execution.id },
  });

  const onClick = () => {
    if (loading || disabled) return;
    execute();
  };

  return (
    <NodeButton onClick={onClick} disabled={disabled}>
      <FontAwesomeIcon icon={loading ? faSpinner : faRepeat} spin={loading} />
    </NodeButton>
  );
};
