import { type FC, type ReactNode } from "react";

import { faRobot } from "@fortawesome/pro-duotone-svg-icons/faRobot";
import { faUserVneck } from "@fortawesome/pro-duotone-svg-icons/faUserVneck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { type AttachmentFragment } from "@app_schema";

import { FileList } from "./file_list";
import { HTML } from "./html";
import { Prose } from "./prose";

type Role = "user" | "system";

const ICONS = {
  user: faUserVneck,
  system: faRobot,
};

const NAMES = {
  user: "You",
  system: "Workflow",
};

const ChatBubbleAvatar: FC<{ role: Role }> = ({ role }) => (
  <div className="flex size-10 items-center justify-center rounded-md bg-slate-200 text-slate-800">
    <FontAwesomeIcon icon={ICONS[role]} />
  </div>
);

export const ChatBubble: FC<{
  html: string;
  role: Role;
  files?: AttachmentFragment[];
  actions?: ReactNode;
}> = ({ html, role, files, actions }) => (
  <div className="flex gap-4">
    <div className="flex-none">
      <ChatBubbleAvatar role={role} />
    </div>
    <div className="flex-auto">
      <div className="flex flex-col gap-2">
        <div className="font-semibold">{NAMES[role]}</div>
        <Prose className="break-all">
          <HTML html={html} />
        </Prose>
        {files && files.length > 0 && <FileList attachments={files} />}
        {actions && <div className="flex gap-2">{actions}</div>}
      </div>
    </div>
  </div>
);
