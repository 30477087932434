import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { type StepInput, StepKindEnum } from "@app_schema";

import { DashboardStepsFormActionBranchFieldset } from "./steps_form_action_branch_fieldset";
import { DashboardStepsFormActionCodeFieldset } from "./steps_form_action_code_fieldset";
import { DashboardStepsFormActionFormatterFieldset } from "./steps_form_action_formatter_fieldset";
import { DashboardStepsFormActionInputFieldset } from "./steps_form_action_input_fieldset";
import { DashboardStepsFormActionIteratorFieldset } from "./steps_form_action_iterator_fieldset";
import { DashboardStepsFormActionLearnFieldset } from "./steps_form_action_learn_fieldset";
import { DashboardStepsFormActionOutputFieldset } from "./steps_form_action_output_fieldset";
import { DashboardStepsFormActionPromptFieldset } from "./steps_form_action_prompt_fieldset";
import { DashboardStepsFormActionSpeechFieldset } from "./steps_form_action_speech_fieldset";
import { DashboardStepsFormActionTranscriptionFieldset } from "./steps_form_action_transcription_fieldset";

export const DashboardStepsFormActionFieldset: FC = () => {
  const form = useFormContext<StepInput>();
  const kind = form.watch("kind");
  if (!kind) return null;

  switch (kind) {
    case StepKindEnum.Branch:
      return <DashboardStepsFormActionBranchFieldset />;
    case StepKindEnum.Code:
      return <DashboardStepsFormActionCodeFieldset />;
    case StepKindEnum.Formatter:
      return <DashboardStepsFormActionFormatterFieldset />;
    case StepKindEnum.Prompt:
      return <DashboardStepsFormActionPromptFieldset />;
    case StepKindEnum.Learn:
      return <DashboardStepsFormActionLearnFieldset />;
    case StepKindEnum.Output:
      return <DashboardStepsFormActionOutputFieldset />;
    case StepKindEnum.Input:
      return <DashboardStepsFormActionInputFieldset />;
    case StepKindEnum.Iterator:
      return <DashboardStepsFormActionIteratorFieldset />;
    case StepKindEnum.Speech:
      return <DashboardStepsFormActionSpeechFieldset />;
    case StepKindEnum.Transcription:
      return <DashboardStepsFormActionTranscriptionFieldset />;
  }
};
