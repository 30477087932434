import { type FC } from "react";
import { useFormContext } from "react-hook-form";

import { useDashboardWorkflowsListQuery } from "@app_schema";

import { SelectField } from "@styled/select_field";

export const WorkflowSelectField: FC<{
  id: string;
  name: string;
  label: string;
  placeholder?: string;
}> = ({ id, name, label, placeholder = "- Workflow -" }) => {
  const { data } = useDashboardWorkflowsListQuery();
  const workflows = data?.workflows.filter((workflow) => !workflow.deleted);
  const form = useFormContext();

  const field = form.register(name, { required: "required" });

  return (
    <SelectField {...field} id={id} name={name} label={label}>
      <option value="" disabled={form.watch(name)}>
        {placeholder}
      </option>

      {workflows?.map((workflow) => (
        <option key={workflow.id} value={workflow.id}>
          {workflow.name}
        </option>
      ))}
    </SelectField>
  );
};
