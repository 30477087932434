import { type FC } from "react";
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form";

import { faIdCardClip as faName } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";

import {
  type Action__BranchFragment,
  type Action__BranchInput,
  type Action__CodeFragment,
  type Action__CodeInput,
  type Action__FormatterFragment,
  type Action__FormatterInput,
  type Action__InputFragment,
  type Action__InputInput,
  type Action__IteratorFragment,
  type Action__IteratorInput,
  type Action__LearnFragment,
  type Action__LearnInput,
  type Action__OutputFragment,
  type Action__OutputInput,
  type Action__PromptFragment,
  type Action__PromptInput,
  type Action__SpeechFragment,
  type Action__SpeechInput,
  type Action__TranscriptionFragment,
  type Action__TranscriptionInput,
  type ConditionFragment,
  type ConditionInput,
  type DashboardStepsDetailsQuery,
  type StepActionInput,
  type StepInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Fieldset } from "@styled/fieldset";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { Sentence } from "@styled/sentence";

import { StepKindField } from "./step_kind_field";
import { DashboardStepsFormActionFieldset } from "./steps_form_action_fieldset";
import { DashboardStepsFormConditionsFieldset } from "./steps_form_conditions_fieldset";

const buildConditionInput = ({
  id,
  lhs,
  rhs,
  operation,
}: ConditionFragment): ConditionInput => ({
  id,
  lhs,
  operation,
  rhs,
});

const buildActionBranchInput = ({
  id,
  workflow,
  params,
}: Action__BranchFragment): Action__BranchInput => ({
  id,
  workflowID: workflow.id,
  params,
});

const buildActionPromptInput = ({
  id,
  model,
  temperature,
  formatter,
  messages,
  tools,
}: Action__PromptFragment): Action__PromptInput => ({
  id,
  model,
  temperature,
  formatter: formatter && {
    id: formatter.id,
    kind: formatter.kind,
    options: formatter.options,
  },
  messages: messages.map((message) => ({
    id: message.id,
    role: message.role,
    template: message.template,
    attachments: message.attachments.map((attachment) => ({
      id: attachment.id,
      kind: attachment.kind,
      path: attachment.path,
    })),
  })),
  tools: tools.map((tool) => ({
    id: tool.id,
    skillID: tool.skill.id,
  })),
});

const buildActionCodeInput = ({
  id,
  image,
  template,
}: Action__CodeFragment): Action__CodeInput => ({
  id,
  image,
  template,
});

const buildActionFormatterInput = ({
  id,
  language,
  template,
}: Action__FormatterFragment): Action__FormatterInput => ({
  id,
  language,
  template,
});

const buildActionLearnInput = ({
  id,
  name,
  code,
  test,
  documentation,
}: Action__LearnFragment): Action__LearnInput => ({
  id,
  name,
  code,
  test,
  documentation,
});

const buildActionInputInput = ({
  id,
  inputKind,
}: Action__InputFragment): Action__InputInput => ({
  id,
  kind: inputKind,
});

const buildActionOutputInput = ({
  id,
  language,
  template,
}: Action__OutputFragment): Action__OutputInput => ({
  id,
  language,
  template,
});

const buildActionIteratorInput = ({
  id,
  iteratorKind,
  timesIterations,
  overKey,
  untilKey,
  iterationLimit,
}: Action__IteratorFragment): Action__IteratorInput => ({
  id,
  kind: iteratorKind,
  timesIterations,
  overKey,
  untilKey,
  iterationLimit,
});

const buildActionSpeechInput = ({
  id,
  template,
  speechFormat,
  speechModel,
  voice,
  speed,
}: Action__SpeechFragment): Action__SpeechInput => ({
  id,
  template,
  format: speechFormat,
  model: speechModel,
  voice,
  speed,
});

const buildActionTranscriptionInput = ({
  id,
  transcriptionModel,
  path,
  temperature,
  prompt,
  format,
}: Action__TranscriptionFragment): Action__TranscriptionInput => ({
  id,
  model: transcriptionModel,
  path,
  temperature,
  prompt,
  format,
});

const buildActionInput = (
  action: DashboardStepsDetailsQuery["workflow"]["step"]["action"],
): StepActionInput => {
  switch (action.__typename) {
    case "Action__Branch":
      return { branch: buildActionBranchInput(action) };
    case "Action__Prompt":
      return { prompt: buildActionPromptInput(action) };
    case "Action__Code":
      return { code: buildActionCodeInput(action) };
    case "Action__Formatter":
      return { formatter: buildActionFormatterInput(action) };
    case "Action__Learn":
      return { learn: buildActionLearnInput(action) };
    case "Action__Input":
      return { input: buildActionInputInput(action) };
    case "Action__Output":
      return { output: buildActionOutputInput(action) };
    case "Action__Iterator":
      return { iterator: buildActionIteratorInput(action) };
    case "Action__Speech":
      return { speech: buildActionSpeechInput(action) };
    case "Action__Transcription":
      return { transcription: buildActionTranscriptionInput(action) };
    default:
      throw new Error(`Unknown: ${action}`);
  }
};

export const DashboardStepsForm: FC<{
  step?: DashboardStepsDetailsQuery["workflow"]["step"] | null;
  loading?: boolean;
  errors?: {
    base?: Array<string> | null;
    name?: Array<string> | null;
    model?: Array<string> | null;
    template?: Array<string> | null;
    temperature?: Array<string> | null;
  } | null;
  save(_: StepInput): void;
}> = ({ step, save, loading, errors }) => {
  const form = useForm<StepInput>({
    defaultValues: step
      ? {
          name: step.name,
          kind: step.kind,
          conditions: step.conditions.map(buildConditionInput),
          action: buildActionInput(step.action),
        }
      : undefined,
  });

  const onSubmit: SubmitHandler<StepInput> = (data) => {
    save(data);
  };

  return (
    <Page>
      <FormProvider {...form}>
        <Form onSubmit={form.handleSubmit(onSubmit)}>
          {errors?.base && (
            <Notification color="rose">
              <Sentence>{errors.base}</Sentence>
            </Notification>
          )}

          <Fieldset>
            <InputField
              {...form.register("name", { required: "required" })}
              id="step_name"
              type="text"
              label="Name:"
              placeholder="Name"
              icon={faName}
              errors={errors}
            />

            <DashboardStepsFormConditionsFieldset />

            <StepKindField />
          </Fieldset>

          <DashboardStepsFormActionFieldset />

          <Button type="submit" loading={loading}>
            Save
          </Button>
        </Form>
      </FormProvider>
    </Page>
  );
};
