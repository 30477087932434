import { type FC } from "react";

import { faRepeat } from "@fortawesome/pro-duotone-svg-icons/faRepeat";

import {
  type ExecutionFragment,
  type RunFragment,
  RunStatusEnum,
  useExecutionRetryMutation,
} from "@app_schema";

import { IconButton } from "@styled/icon_button";

export const ExecutionRetryIconButton: FC<{
  run: RunFragment;
  execution: ExecutionFragment;
}> = ({ run, execution }) => {
  const disabled = run.status === RunStatusEnum.Running;
  const [execute, { loading }] = useExecutionRetryMutation({
    variables: { id: execution.id },
  });

  const onClick = () => {
    if (loading || disabled) return;
    execute();
  };

  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      icon={faRepeat}
      loading={loading}
    />
  );
};
