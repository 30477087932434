import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { type StepInput } from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { TextAreaField } from "@styled/text_area_field";

import { TemplateLanguageSelectField } from "./steps/template_language_select_field";

export const DashboardStepsFormActionOutputFieldset: FC = () => {
  const { register, unregister, watch } = useFormContext<StepInput>();

  useEffect(() => {
    register("action.output");
    return () => unregister("action.output");
  }, [register, unregister]);

  return (
    <Fieldset>
      <TemplateLanguageSelectField
        {...register("action.output.language", { required: "required" })}
        placeholder={!watch("action.output.language")}
        id="step_action_output_language"
        label="Language:"
      />

      <TextAreaField
        {...register("action.output.template", { required: "required" })}
        id="step_action_output_template"
        label="Template:"
        placeholder="Template"
        rows={8}
      />
    </Fieldset>
  );
};
