import { type FC, type ReactNode } from "react";

import { type IconProp } from "@fortawesome/fontawesome-svg-core";

import { Control } from "./control";
import { Field } from "./field";
import { Hint } from "./hint";
import { Label } from "./label";
import { Sentence } from "./sentence";
import { Tooltip } from "./tooltip";
import { FormState } from "./types";

export type BaseFieldProps = {
  id?: string;
  name: string;
  tooltip?: { tip: ReactNode };
  hint?: ReactNode;
  label?: ReactNode;
  icon?: IconProp;
  errors?: Record<string, string[] | string | undefined | null> | null;
};

export const BaseField: FC<
  BaseFieldProps & {
    children({ state }: { state: FormState }): ReactNode;
  }
> = ({ id, name, label, icon, errors, hint, tooltip, children }) => {
  const error = errors && errors[name];
  const state: FormState = (() => {
    if (errors === undefined || errors === null) return FormState.Default;
    return errors[name] ? FormState.Error : FormState.Valid;
  })();

  return (
    <Field>
      {label && (
        <Label htmlFor={id}>
          {label} {tooltip && <Tooltip {...tooltip} />}
        </Label>
      )}
      <Control icon={icon} state={state}>
        {children({ state })}
      </Control>
      {hint && <Hint color="slate">{hint}</Hint>}
      {error && (
        <Hint color="rose">
          <Sentence>{error}</Sentence>
        </Hint>
      )}
    </Field>
  );
};

BaseField.displayName = "BaseField";
