import { type FC, type FormEvent, useState } from "react";

import { faMemoCircleInfo } from "@fortawesome/pro-duotone-svg-icons";
import { faIdCardClip } from "@fortawesome/pro-duotone-svg-icons/faIdCardClip";

import {
  type SkillErrors,
  type SkillFragment,
  type SkillInput,
} from "@app_schema";

import { Button } from "@styled/button";
import { Field } from "@styled/field";
import { Form } from "@styled/form";
import { InputField } from "@styled/input_field";
import { JSONSchemaField } from "@styled/json_schema_field";
import { Label } from "@styled/label";
import { Notification } from "@styled/notification";
import { Page } from "@styled/page";
import { SelectField } from "@styled/select_field";
import { Sentence } from "@styled/sentence";
import { TextAreaField } from "@styled/text_area_field";

import { SkillPromptForm } from "./skill_prompt_form";

const DEFAULT_PARAMETERS = {
  type: "object",
  properties: {},
  required: [],
};

export const DashboardSkillsForm: FC<{
  skill?: SkillFragment | null;
  loading?: boolean;
  errors?: SkillErrors | null;
  save(_: SkillInput): void;
}> = ({ skill, loading, errors, save }) => {
  const [language, setLanguage] = useState<string | undefined>(skill?.language);
  const [parameters, setParameters] = useState(
    skill?.parameters ?? DEFAULT_PARAMETERS,
  );
  const [name, setName] = useState<string>(skill?.name ?? "");
  const [code, setCode] = useState<string>(skill?.code ?? "");
  const [test, setTest] = useState<string>(skill?.test ?? "");
  const [description, setDescription] = useState(skill?.description ?? "");
  const [documentation, setDocumentation] = useState<string>(
    skill?.documentation ?? "",
  );

  const onSubmit = (event: FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!language) return;

    save({
      language,
      name,
      description,
      parameters,
      code,
      test,
      documentation,
    });
  };

  return (
    <Page>
      <SkillPromptForm
        language={language}
        onLanguage={setLanguage}
        onName={setName}
        onDescription={setDescription}
        onParameters={setParameters}
        onCode={setCode}
        onTest={setTest}
        onDocumentation={setDocumentation}
      />

      <Form onSubmit={onSubmit}>
        {errors?.base && (
          <Notification color="rose">
            <Sentence>{errors.base}</Sentence>
          </Notification>
        )}

        <SelectField
          id="language"
          name="language"
          label="Language:"
          value={language ?? ""}
          errors={errors}
          onChange={(event) => setLanguage(event.target.value)}
        >
          <option value="" disabled={!!language}>
            - Language -
          </option>
          <option value="node">Node</option>
          <option value="ruby">Ruby</option>
          <option value="python">Python</option>
        </SelectField>

        <InputField
          id="name"
          name="name"
          type="text"
          label="Name:"
          placeholder="Name"
          icon={faIdCardClip}
          value={name}
          errors={errors}
          onChange={(event) => setName(event.target.value)}
        />

        <InputField
          id="description"
          name="description"
          type="text"
          label="Description:"
          placeholder="Description"
          icon={faMemoCircleInfo}
          value={description}
          errors={errors}
          onChange={(event) => setDescription(event.target.value)}
        />

        <Field>
          <Label>Parameters:</Label>
          <JSONSchemaField schema={parameters} onChange={setParameters} />
        </Field>

        <TextAreaField
          mono
          id="code"
          name="code"
          label="Code:"
          placeholder="Code"
          value={code}
          errors={errors}
          onChange={(event) => setCode(event.target.value)}
        />

        <TextAreaField
          mono
          id="test"
          name="test"
          label="Test:"
          placeholder="Test"
          value={test}
          errors={errors}
          onChange={(event) => setTest(event.target.value)}
        />

        <TextAreaField
          id="documentation"
          name="documentation"
          label="Documentation:"
          placeholder="Documentation"
          value={documentation}
          errors={errors}
          onChange={(event) => setDocumentation(event.target.value)}
        />

        <Button type="submit" loading={loading}>
          Save
        </Button>
      </Form>
    </Page>
  );
};
