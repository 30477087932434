import { type FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Action__IteratorKind, StepInput } from "@app_schema";

import { Fieldset } from "@styled/fieldset";
import { InputField } from "@styled/input_field";
import { SelectField } from "@styled/select_field";

export const DashboardStepsFormActionIteratorFieldset: FC = () => {
  const { register, unregister, watch } = useFormContext<StepInput>();
  const kind = watch("action.iterator.kind");

  useEffect(() => {
    register("action.iterator");
    return () => unregister("action.iterator");
  }, [register, unregister]);

  const limited =
    kind === Action__IteratorKind.Over || kind === Action__IteratorKind.Until;

  return (
    <Fieldset>
      <SelectField
        {...register("action.iterator.kind", { required: "required" })}
        id="step_action_iterator_kind"
        label="Type:"
      >
        <option disabled={!!kind} value="">
          - Type -
        </option>
        <option value={Action__IteratorKind.Times}>Times</option>
        <option value={Action__IteratorKind.Over}>Over</option>
        <option value={Action__IteratorKind.Until}>Until</option>
      </SelectField>

      {kind === Action__IteratorKind.Times && (
        <InputField
          {...register("action.iterator.timesIterations", {
            required: "required",
            valueAsNumber: true,
          })}
          id="step_action_iterator_iterations"
          label="Iterations:"
          placeholder="Iterations"
          type="number"
        />
      )}

      {kind === Action__IteratorKind.Over && (
        <InputField
          {...register("action.iterator.overKey", { required: "required" })}
          id="step_action_iterator_over_key"
          tooltip={{
            tip: <>A step / variables to loop over (e.g. step.variable)</>,
          }}
          label="Over Key"
          placeholder="e.g. x.y.z"
          type="string"
        />
      )}

      {kind === Action__IteratorKind.Until && (
        <InputField
          {...register("action.iterator.untilKey", { required: "required" })}
          id="step_action_iterator_until_key"
          tooltip={{
            tip: <>A step / variables to loop until (e.g. step.variable)</>,
          }}
          label="Until Key"
          placeholder="e.g. x.y.z"
          type="string"
        />
      )}

      {limited && (
        <InputField
          {...register("action.iterator.iterationLimit", {
            valueAsNumber: true,
          })}
          id="step_action_iterator_iteration_limit"
          label="Iteration Limit"
          type="number"
        />
      )}
    </Fieldset>
  );
};
