import { type FC } from "react";
import { Controller, useFormContext } from "react-hook-form";

import {
  type Skill,
  type StepInput,
  useDashboardSkillsListQuery,
} from "@app_schema";

import { Checkbox } from "@styled/checkbox";
import { Field } from "@styled/field";
import { Label } from "@styled/label";

const ToolCheckbox: FC<{
  name?: string;
  skill: Pick<Skill, "id" | "name" | "description">;
  checked?: boolean;
  onChange(checked?: boolean): void;
}> = ({ name, skill, checked, onChange }) => (
  <Label className="flex items-center gap-4 rounded-md border border-slate-100 px-3 py-2">
    <Checkbox
      name={name}
      value={skill.id}
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
    />

    <div className="flex flex-col">
      <div className="font-semibold text-slate-600">{skill.name}</div>
      <div className="font-normal text-slate-400">{skill.description}</div>
    </div>
  </Label>
);

export const DashboardStepsFormActionPromptToolsFieldset: FC<{
  name?: `action.prompt.tools`;
}> = ({ name = "action.prompt.tools" }) => {
  const { control } = useFormContext<StepInput>();
  const { data } = useDashboardSkillsListQuery();
  const skills = data?.skills;

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={[]}
      render={({ field: { value, onChange } }) => {
        const selections = new Set(value?.map(({ skillID }) => skillID) ?? []);

        const onInsert = (skill: Pick<Skill, "id">) => {
          onChange([...(value ?? []), { skillID: skill.id }]);
        };

        const onDelete = (skill: Pick<Skill, "id">) => {
          onChange(value?.filter(({ skillID }) => skillID !== skill.id) ?? []);
        };

        return (
          <Field>
            <Label>Tools:</Label>
            {skills?.map((skill) => (
              <ToolCheckbox
                key={skill.id}
                name={name}
                skill={skill}
                checked={selections.has(skill.id)}
                onChange={(checked) => {
                  if (checked) onInsert(skill);
                  else onDelete(skill);
                }}
              />
            ))}
          </Field>
        );
      }}
    />
  );
};
