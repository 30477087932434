import { type FC, useState } from "react";

import { useDashboardSkillsListQuery } from "@app_schema";

import { useDebounce } from "@application/hooks/use_debounce";

import { Headline } from "@styled/headline";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { NewLink } from "@styled/new_link";
import { Page } from "@styled/page";
import { SearchInputField } from "@styled/search_input_field";
import { ViewLink } from "@styled/view_link";

import { SkillDestroyButton } from "./skill_destroy_button";

export const DashboardSkillsList: FC = () => {
  const [query, setQuery] = useState<string | undefined>();
  const { data, loading, error, refetch } = useDashboardSkillsListQuery({
    variables: { query: useDebounce(query) },
  });
  const retry = () => refetch();

  const skills = data?.skills?.filter(({ deleted }) => !deleted);

  return (
    <Page loading={loading} error={error} retry={retry}>
      <Headline title="Skills">
        <NewLink to="/dashboard/skills/new" />
      </Headline>

      <SearchInputField query={query} onSearch={setQuery} />

      <List>
        {skills?.map((skill) => (
          <ListItem>
            <ListItemText>
              <ListItemTitle>{skill.name}</ListItemTitle>
              <ListItemSummary>{skill.description}</ListItemSummary>
            </ListItemText>

            <ListItemControls>
              <ViewLink to={`/dashboard/skills/${skill.id}`} key={skill.id} />
              <SkillDestroyButton skill={skill} />
            </ListItemControls>
          </ListItem>
        ))}
      </List>
    </Page>
  );
};
