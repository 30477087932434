import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faAlignJustify } from "@fortawesome/pro-duotone-svg-icons/faAlignJustify";
import { faArrowLeft } from "@fortawesome/pro-duotone-svg-icons/faArrowLeft";
import { faArrowRight } from "@fortawesome/pro-duotone-svg-icons/faArrowRight";
import { faCode } from "@fortawesome/pro-duotone-svg-icons/faCode";
import { faCodeBranch } from "@fortawesome/pro-duotone-svg-icons/faCodeBranch";
import { faEarListen } from "@fortawesome/pro-duotone-svg-icons/faEarListen";
import { faGraduationCap } from "@fortawesome/pro-duotone-svg-icons/faGraduationCap";
import { faMicrophone } from "@fortawesome/pro-duotone-svg-icons/faMicrophone";
import { faRepeat } from "@fortawesome/pro-duotone-svg-icons/faRepeat";
import { faRobot } from "@fortawesome/pro-duotone-svg-icons/faRobot";

import { StepKindEnum } from "@app_schema";

export const STEP_KIND_ICON: Record<StepKindEnum, IconDefinition> = {
  [StepKindEnum.Branch]: faCodeBranch,
  [StepKindEnum.Code]: faCode,
  [StepKindEnum.Formatter]: faAlignJustify,
  [StepKindEnum.Input]: faArrowRight,
  [StepKindEnum.Iterator]: faRepeat,
  [StepKindEnum.Learn]: faGraduationCap,
  [StepKindEnum.Output]: faArrowLeft,
  [StepKindEnum.Prompt]: faRobot,
  [StepKindEnum.Speech]: faMicrophone,
  [StepKindEnum.Transcription]: faEarListen,
};
