import { type FC, type ReactNode } from "react";

import { faClock } from "@fortawesome/pro-duotone-svg-icons/faClock";
import { faWebhook } from "@fortawesome/pro-duotone-svg-icons/faWebhook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Headline } from "@styled/headline";
import { NewLink } from "@styled/new_link";
import { Page } from "@styled/page";
import { TabList } from "@styled/tab_list";
import { TabNavLink } from "@styled/tab_nav_link";

export const DashboardTriggersContainer: FC<{
  title?: string;
  loading: boolean;
  children: ReactNode;
}> = ({ loading, title = "Triggers", children }) => (
  <Page loading={loading}>
    <Headline title={title}>
      <NewLink to="/dashboard/triggers/webhooks/new">Webhook</NewLink>
      <NewLink to="/dashboard/triggers/schedules/new">Schedule</NewLink>
    </Headline>

    <TabList>
      <TabNavLink to="/dashboard/triggers/webhooks">
        <FontAwesomeIcon icon={faWebhook} />
        <span>Webhooks</span>
      </TabNavLink>
      <TabNavLink to="/dashboard/triggers/schedules">
        <FontAwesomeIcon icon={faClock} />
        <span>Schedules</span>
      </TabNavLink>
    </TabList>

    {children}
  </Page>
);
