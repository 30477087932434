import { type FC, useState } from "react";

import { type ExecutionFragment } from "@app_schema";

import { Button } from "@styled/button";

import { ExecutionDetailModal } from "./execution_detail_modal";

const NAMES = {
  prompt: "Prompt",
  output: "Output",
};

export const ExecutionDetailButton: FC<{
  execution: ExecutionFragment;
  display: "prompt" | "output";
}> = ({ execution, display }) => {
  const [visible, setVisible] = useState<boolean>(false);
  const onToggle = () => setVisible((visible) => !visible);

  return (
    <>
      <Button type="button" color="slate" onClick={onToggle}>
        {NAMES[display]}
      </Button>
      {visible && (
        <ExecutionDetailModal
          execution={execution}
          display={display}
          onClose={onToggle}
        />
      )}
    </>
  );
};
