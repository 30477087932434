import { type FC, useState } from "react";

import { useDashboardWorkflowsListQuery } from "@app_schema";

import { useDebounce } from "@application/hooks/use_debounce";

import { Fields } from "@styled/fields";
import { Headline } from "@styled/headline";
import { List } from "@styled/list";
import { ListItem } from "@styled/list_item";
import { ListItemControls } from "@styled/list_item_controls";
import { ListItemSummary } from "@styled/list_item_summary";
import { ListItemText } from "@styled/list_item_text";
import { ListItemTitle } from "@styled/list_item_title";
import { MineSwitch } from "@styled/mine_switch";
import { NewLink } from "@styled/new_link";
import { Page } from "@styled/page";
import { SearchInputField } from "@styled/search_input_field";
import { ViewLink } from "@styled/view_link";

import { RunRecent } from "./run_recent";
import { WorkflowCloneButton } from "./workflow_clone_button";
import { WorkflowsDestroyButton } from "./workflows_destroy_button";

export const DashboardWorkflowsList: FC = () => {
  const [mine, setMine] = useState<boolean | undefined>();
  const [query, setQuery] = useState<string | undefined>();
  const { data, error, loading, refetch } = useDashboardWorkflowsListQuery({
    variables: { mine, query: useDebounce(query) },
  });
  const retry = () => refetch();

  const workflows = data?.workflows.filter(({ deleted }) => !deleted);

  return (
    <Page loading={loading} error={error} retry={retry}>
      <Headline title="Workflows">
        <NewLink to="/dashboard/workflows/new" />
      </Headline>

      <Fields>
        <SearchInputField query={query} onSearch={setQuery} />

        <MineSwitch mine={mine} onChange={setMine} />
      </Fields>

      <List>
        {workflows?.map((workflow) => (
          <ListItem key={workflow.id}>
            <ListItemText>
              <ListItemTitle>
                {workflow.name}
                {!workflow.user.me && <> by {workflow.user.name}</>}
              </ListItemTitle>

              {workflow.runs.length > 0 && (
                <ListItemSummary>
                  <RunRecent runs={workflow.runs} />
                </ListItemSummary>
              )}
            </ListItemText>

            <ListItemControls>
              <ViewLink to={`/dashboard/workflows/${workflow.id}`} />
              <WorkflowCloneButton workflow={workflow} />
              <WorkflowsDestroyButton workflow={workflow} />
            </ListItemControls>
          </ListItem>
        ))}
      </List>
    </Page>
  );
};
